import React from 'react';
import BtnComp from '../ButtonComp/BtnComp';
import "./KozijnenWaaromCard.css";

const KozijenWaaromCard = (props) => {
  return (
    <div className='kozijenwaaromcard-main'>
        <h4>
            {props.waaromTitle}
        </h4>
        <p>
            {props.waaromPara}
        </p>
        <div className='kozijen-btns'>
            <div className='kozijen-waroom-btn1'>
                <BtnComp
                    linkDataText={props.kozijenText1}
                    linkDataUrl={props.kozijenLink1}/>
            </div>
            <div className='kozijen-waroom-btn2'>
                <BtnComp
                     linkDataText={props.kozijenText2}
                     linkDataUrl={props.kozijenLink1}/>
            </div>
           
        </div>
    </div>
  )
}

export default KozijenWaaromCard;
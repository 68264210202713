import React from 'react';
import karch1 from "../../assets/Images/karch1.png";
import karch2 from "../../assets/Images/karch2.png";
import IsBu from "../../assets/Images/I&SBouw.png";
import "./Kracht.css";
const Kracht = () => {
    return (
        <div className='kracht-main'>
            <div className='container'>
                <div className='kracht-sec'>
                    <div className='kracht-content1'>
                        <div className='kracht-content1-image mt-5' data-aos="fade-up" data-aos-duration="3000">
                            <img src={karch1} alt="kracht-logo" />
                        </div>
                        <div className='kracht-content1-image' data-aos="fade-up" data-aos-duration="3000">
                            <img src={karch2} alt="kracht-logo" />
                        </div>
                    </div>
                    <div className='kracht-content2' data-aos="flip-left" data-aos-duration="3000">
                        <h3>
                            De kracht van
                        </h3>
                        <div className='kracht-content2-image'>
                            <img src={IsBu} alt="kracht-logo" />
                        </div>
                        <div className='kracht-content2-para'>
                            <p>
                                I&SBouw is een veelzijdig bouwbedrijf gevestigd in Rotterdam dat bekend staat om zijn uitstekende organisatie
                                en regie van projecten. Ons team is gespecialiseerd in de realisatie van diverse projecten, waaronder
                                woningbouw, utiliteitsbouw en renovatie- en transformatieprojecten. Wij werken nauw samen met onze
                                ketenpartners om enthousiast en vol toewijding te werken aan verschillende projecten. Onze unieke manier
                                van samenwerken stelt ons in staat om een betrokken organisatie te zijn en om een optimaal en transparant
                                bouwproces te bieden aan onze klanten.
                            </p>
                        </div>

                        <div className='kracht-number-sec'>
                            <div className='kracht-number1'>
                                <h4>
                                    15+
                                </h4>
                                <p>
                                    Jaar ervaring
                                </p>
                            </div>
                            <div className='kracht-number1'>
                                <h4>
                                    20+
                                </h4>
                                <p>
                                    Succesvol afgeleverde projecten
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kracht
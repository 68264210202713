import React from "react";
// import ceoLogo from "../../assets/Images/ceo.jpg";
import "./CeoSection.css";

const CeoSection = () => {
  return (
    <div className="ceo-main">
      {/* <div className="ceo-logo">
        <img src={ceoLogo} alt="ceo-logo" />
      </div> */}
      <div className="ceo-content">
        {/* <p>
          Ontmoet onze visionaire leider, Selahattin Yavuz, de drijvende kracht
          achter ons succesverhaal. Zijn reis begon na zijn opleiding bouwkunde
          aan de MTS, waar hij zijn passie voor architectuur ontdekte.
          Vastberaden om zijn kennis te verdiepen, zette hij zijn studie voort
          aan de HTS, voordat hij zijn horizon verder verbreedde door een
          opleiding bouwkunde aan de prestigieuze Technische Universiteit Delft
          te volgen.
        </p>
        <p>
          Als een gedreven architect in opleiding leerde hij de kunst van het
          verbinden van diverse uitgangspunten en belangen, en deze te smeden
          tot prachtige gebouwen. Het was in de vroege jaren '90 dat hij zijn
          eerste stappen zette als ondernemer, met een sterke nadruk op
          ontwerpen en de daadwerkelijke bouwprojecten. Hoewel hij nu
          voornamelijk in de wereld van management en bouw opereert, blijft zijn
          hart diep geworteld in de architectuur, gedreven door een
          onverwoestbare passie voor het creëren van verbindende ruimtes.
        </p>
        <p>
          Wat Selahattin Yavuz echt energie geeft, is de vrijheid om te creëren,
          het vertrouwen in zijn vakmanschap en de wetenschap dat mensen waarde
          hechten aan het eindresultaat van zijn inspanningen. Hij is niet
          iemand die problemen ziet, maar een visionair die zijn creatieve
          vrijheid benut om betaalbare oplossingen te vinden. Bij hem draait
          alles om het verwezenlijken van visies en het bouwen van dromen.
          Welkom in de wereld van innovatie en vakmanschap, waar Selahattin
          Yavuz de koers uitzet en ons inspireert om architectuur op nieuwe
          hoogten te brengen.
        </p> */}
        <p>
          Ontmoet het ISBouw team, de drijvende kracht achter ons succesverhaal.
          Ons team bestaat uit gedreven professionals met een passie voor
          architectuur en bouw. Hun kennis en ervaring zijn opgedaan via
          opleidingen aan gerenommeerde instellingen en door jarenlange
          praktijkervaring in zowel ontwerp als uitvoering van bouwprojecten.
        </p>
        <p>
          Bij ISBouw staat samenwerking centraal; ons team weet diverse
          uitgangspunten en belangen te verbinden en te smeden tot prachtige
          gebouwen. Sinds de vroege jaren '90 zetten wij ons in als innovatieve
          en betrouwbare partner in de bouwsector, met een sterke focus op zowel
          het ontwerp als de daadwerkelijke uitvoering van projecten. Hoewel ons
          team zich voornamelijk richt op management en bouw, blijft onze passie
          voor architectuur diep geworteld, gedreven door de ambitie om
          verbindende ruimtes te creëren.
        </p>
        <p>
          Wat ons echt energie geeft, is de vrijheid om te creëren, het
          vertrouwen in ons vakmanschap en de wetenschap dat mensen waarde
          hechten aan het eindresultaat van onze inspanningen. Wij zijn niet
          degenen die problemen zien, maar visionairs die onze creatieve
          vrijheid benutten om betaalbare oplossingen te vinden. Bij ISBouw
          draait alles om het verwezenlijken van visies en het bouwen van
          dromen. Welkom in de wereld van innovatie en vakmanschap, waar het
          ISBouw team de koers uitzet en inspireert om architectuur op nieuwe
          hoogten te brengen.
        </p>
      </div>
    </div>
  );
};

export default CeoSection;

import React from 'react';
import PlatteCard from '../SubComponents/PlatteCard/PlatteCard';
import People from "../../assets/Images/people.png";
import Hand from "../../assets/Images/hands.png";
import Tick from "../../assets/Images/tick.png";
import Mess from "../../assets/Images/message.png";
import "./PlatteList.css";

const PlatteList = () => {
  return (
    <div className='container'>
        <div className='plattelist-main'>
              <div className='plattelist-content' data-aos="zoom-in-up" data-aos-duration="3000">
                <PlatteCard
                platteLogo={People}
                platteHead="Platte organisatie"
                plattePara="Een organisatiestructuur opgebouwd uit korte lijnen, uw contactpersoon heeft het volledige overzicht over 
                uw project. Beslissingen kunnen snel worden genomen worden en geven we het bouwproces optimaal vorm. Dat zorgt voor 
                een soepel werkproces."/>
            </div>
              <div className='plattelist-content platte-mar' data-aos="zoom-in-up" data-aos-duration="3000">
                <PlatteCard
                platteLogo={Hand}
                platteHead="Afspraak is afspraak"
                plattePara="Een organisatiestructuur opgebouwd uit korte lijnen, uw contactpersoon heeft het volledige overzicht over uw 
                project. Beslissingen kunnen snel worden genomen worden en geven we het bouwproces optimaal vorm. Dat zorgt voor een 
                soepel werkproces."/>
            </div>
              <div className='plattelist-content' data-aos="zoom-in-up" data-aos-duration="3000">
                <PlatteCard
                platteLogo={Tick}
                platteHead="Kwaliteit"
                plattePara="Ieder project ongeacht de grootte of afmeting van het werk krijgt de aandacht die het verdient. We willen dat 
                het werk dat we afleveren gewoon goed is, wij denken als bouwpartner met u mee. Door onze manier van samenwerken komen we 
                uiteindelijk verder."/>
            </div>
              <div className='plattelist-content platte-mar' data-aos="zoom-in-up" data-aos-duration="3000">
                <PlatteCard
                platteLogo={Mess}
                platteHead="Persoonlijk contact"
                plattePara="We overleggen, denken mee en adviseren. We schuiven daarom graag vroeg in het traject aan, het liefst voor de
                eerste strepen op papier staan. Door zo vroeg aan te schuiven kunnen we onze ervaring inzetten om ook voor uw project 
                voordelig te zijn."/>
            </div>
        </div>
    </div>
  )
}

export default PlatteList;
import React from 'react';
import "./BtnComp.css";
import { Link } from 'react-router-dom';
const BtnComp = (props) => {
  return (
    <div className='btncomp-main'>
        {/* <Link to={props.linkdata}>{props.linktext}</Link> */}
        <Link to={props.linkDataUrl}>
            {props.linkDataText}         
        </Link>
    </div>
  )
}
export default BtnComp;
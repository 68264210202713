import React from 'react';
import { Link } from 'react-router-dom';
import islogo from "../../assets/Images/islogo.png";
import linkedin from "../../assets/Images/linkedin.png";
import facebook from "../../assets/Images/facebook.png";
import intagram from "../../assets/Images/instagram.png";
import "./IsBouwMedia.css";

const IsBouwMedia = () => {
    return (
        <div className='ismedia-main'>
            <div className='ismedia-logo'>
                <img src={islogo} alt="ismedia-logo" />
            </div>
            <div className='ismedia-icons'>
                <h3>
                    Volg ons online
                </h3>
                <Link to="https://www.facebook.com/profile.php?id=61553822180005">
                    <img src={facebook} alt='face-logo' />
                </Link>
                <Link to="https://instagram.com/isbouw">
                    <img src={intagram} alt='insta-logo' />
                </Link>
                <Link to="https://www.linkedin.com/company/isbouw/?viewAsMember=true">
                    <img src={linkedin} alt='linkedin-logo' />
                </Link>
            </div>
        </div>
    )
}
export default IsBouwMedia;
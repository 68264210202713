import React from 'react';
import "./ProjectenPara.css";
const ProjectenPara = (props) => {
  return (
    <div className='projectenpara-main'>
        <h3>
            {props.projectheading}
        </h3>
        <p>
            {props.projectparas}
        </p>
    </div>
  )
}
export default ProjectenPara;
import React from 'react';
import { useState } from 'react';
import ButtonComp from '../Component/SubComponents/ButtonComp/ButtonComp';

const Questions=() =>{
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [answers, setAnswers] = useState([]); 

    const handleNextQuestion =  (response) => {
      setAnswers((prevAnswers) => [...prevAnswers, response]);
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    };
    const handleCompleteQuestion = () => {
        setCurrentQuestion(1);
        setAnswers([]);
    };
    console.log(answers)

    return (
      <div className='listbutton-main'>
        {currentQuestion === 1 && (
           <div className='listbutton-content1'>
            <p>⦁     Is het object winstgevend?</p>
            <div className='listbutton-content1-button'>
                <div className='content1-button1' onClick={() => handleNextQuestion(true)}>
                    <ButtonComp
                    linktext="ja"
                    linkdata="#"/>
                </div>
                <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                    <ButtonComp
                    linktext="Ik weet het niet"
                    linkdata="#"/>
                </div>
                <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                    <ButtonComp
                    linktext="nee"
                    linkdata="#"/>
                </div>
            </div>
          </div>
        )}
        {currentQuestion === 2 && (
           <div className='listbutton-content1'>
            <p>⦁     Weet u hoe u het project gaat financieren?</p>
            <div className='listbutton-content1-button'>
                <div className='content1-button1' onClick={() => handleNextQuestion(true)}>
                    <ButtonComp
                    linktext="ja"
                    linkdata="#"/>
                </div>
                <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                    <ButtonComp
                    linktext="Ik weet het niet"
                    linkdata="#"/>
                </div>
                <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                    <ButtonComp
                    linktext="nee"
                    linkdata="#"/>
                </div>
            </div>
          </div>
        )}
        {currentQuestion === 3 && (
         <div className='listbutton-content1'>
            <p>⦁     Heeft u uw vergunning in orde?</p>
            <div className='listbutton-content1-button'>
                <div className='content1-button1' onClick={() => handleNextQuestion(true)}>
                    <ButtonComp
                    linktext="ja"
                    linkdata="#"/>
                </div>
                <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                    <ButtonComp
                    linktext="Ik weet het niet"
                    linkdata="#"/>
                </div>
                <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                    <ButtonComp
                    linktext="nee"
                    linkdata="#"/>
                </div>
            </div>
        </div>
        )}
        {currentQuestion === 4 && (
          <div>
            <h2>Survey Completed!</h2>
            <p>Thank you for participating in the survey.</p>
            <div onClick={handleCompleteQuestion}>
              <ButtonComp
              linktext="Done"
              linkdata = ""/>
            </div>
          </div>
        )}
      </div>
    );
  };

export default Questions
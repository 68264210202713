import React from 'react';
import "./ProjectenCard.css";
import { Link } from 'react-router-dom';
const ProjectenCard =(props)=> {
  return (
    <div className='Projecten-main'>
      <Link to={props.ProLink}>
        <div className='Projecten-image'>
            <img src={props.ProImage} alt="pro-logo"/>
        </div>
        <h3>
            {props.ProHead}
        </h3>
      </Link>
    </div>
  )
}
export default ProjectenCard;
import React from 'react';
import MainHeader from '../Component/MainHeader/MianHeader';
import ProjectenPara from '../Component/SubComponents/ProjectenPara/ProjectenPara';
import "./Kozijnen.css";
import IsBouwMedia from '../Component/IsBouwMedia/IsBouwMedia';
import Footer from '../Component/Footer/Footer';
import kozijnenImage from "../assets/Images/kozijnen-ontdek.png";
import KozijenWaaromCard from '../Component/SubComponents/kozijenWaaromCard/KozijenWaaromCard';
import { Link } from 'react-router-dom';

const Kozijnen = ()=> {
  return (
  <>
  <MainHeader/>
    <div className='kozijnen-main'>
        <div className='kozijnen-title'>
            <ProjectenPara
            projectheading="Kozijnen"
            projectparas="Wij bieden hoogwaardige houten en aluminium kozijnen op maat van uw stijl en behoeften."/>
        </div>
        <div className='kozijnen-specialist text-center'>
            <h4>
                <span>IS</span> Bouw Specialist in Hout en Aluminium Kozijnen
            </h4>
        </div>
        <div className='container'>
            <div className='kozijnen-ontdek'>
                <div className='kozijnen-ontdek-para'>
                    <p>
                        Ontdek de perfecte kozijnoplossing voor uw project bij IS Bouw! Wij bieden hoogwaardige houten en aluminium
                        kozijnen, op maat gemaakt naar uw wensen en stijl.
                    </p>
                </div>
                <div className='kozijnen-ontdek-image'>
                    <img src={kozijnenImage} alt='kozijnen-logo'/>
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='kozijen-waaroom'>
                <div className='kozijen-waaroom-keizen'>
                    <h3>
                        Waarom kiezen voor IS Bouw?
                    </h3>
                    <p>
                        Ontdek de belangrijkste voordelen van onze raamkozijnen
                    </p>
                </div>
                <div className='kozijen-maincards'>
                    <div className='kozijnen-card1'>
                        <KozijenWaaromCard
                        waaromTitle="Kwaliteit & Duurzaamheid"
                        waaromPara="Onze kozijnen zijn weerbestendig en gaan lang mee, waardoor de hoogste kwaliteit gegarandeerd is."
                        kozijenText1="Weersbestendig"
                        kozijenLink1="#"
                        kozijenText2="Langdurig"
                        kozijenLink2="#"/>
                    </div>
                    <div className='kozijnen-card2'>
                        <KozijenWaaromCard
                        waaromTitle="Energie-efficiëntie"
                        waaromPara="We maken kozijnen op maat voor uw specifieke behoeften en zorgen ervoor dat ze passen bij de uitstraling van uw huis."
                        kozijenText1="Isolerend"
                        kozijenLink1="#"
                        kozijenText2="Energiebesparend"
                        kozijenLink2="#"/>
                    </div>
                    <div className='kozijnen-card3'>
                        <KozijenWaaromCard
                        waaromTitle="Maatwerk & Stijl"
                        waaromPara="We maken kozijnen op maat voor uw specifieke behoeften en zorgen ervoor dat ze passen bij de uitstraling van uw huis."
                        kozijenText1="Op maat gemaakt"
                        kozijenLink1="#"
                        kozijenText2="Stylish"
                        kozijenLink2="#"/>
                    </div>
                    <div className='kozijnen-card4'>
                        <KozijenWaaromCard
                        waaromTitle="Professionele installatie"
                        waaromPara="Ons deskundig team zorgt voor een nauwkeurige en vakkundige plaatsing van uw kozijnen."
                        kozijenText1="Deskundige installatie"
                        kozijenLink1="#"
                        kozijenText2="Reliable service"
                        kozijenLink2="#"/>
                    </div>
                </div>
                <div className='contact-anchor text-center'>
                    <Link to="/contactus">Contact</Link>
                </div>
            </div>
        </div>
    </div>
    <IsBouwMedia/>
    <Footer/>
  </>
  )
}

export default Kozijnen;
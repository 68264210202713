import React from 'react';
import "./HeadParaBorder.css";
const HeadParaBorder = (props) => {
  return (
    <div className='headpara-mian'>
        <h3>
            {props.hphead}
        </h3>
        <p>
            {props.hppara}
        </p>
        <h4>
            {props.hpspan}
        </h4>
    </div>
  )
}
export default HeadParaBorder;
import React from 'react';
import "./PlatteCard.css";

const PlatteCard = (props) =>{
  return (
    <div className='platte-main'>
        <div className='platte-image'>
            <img src={props.platteLogo} alt="platte-logo"/>
        </div>
        <h3>
            {props.platteHead}
        </h3>
        <p>
            {props.plattePara}
        </p>
    </div>
  )
}
export default PlatteCard;
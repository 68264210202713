import React from 'react';
import ProjectenLink from "../Component/SubComponents/ProjectenLink/ProjectenLink";
import ProjectenImage from '../Component/SubComponents/ProjectenImage/ProjectenImage';
import IsBouwMedia from '../Component/IsBouwMedia/IsBouwMedia';
import Footer from '../Component/Footer/Footer';
import MainHeader from '../Component/MainHeader/MianHeader';

const Page4 = () => {
  return (
    <>
    <MainHeader/>
    <div className='page4-main'>
        <ProjectenLink
        projectHead="Projecten"
        projectlink1="#"
        projecttext1="Home"
        projectlink2="#"
        projecttext2="Portfolio"/>
        <div className='page4-images'>
            <ProjectenImage/>
        </div>
        <div className='page4-isbouw'>
            <IsBouwMedia/>
        </div>
        <div className='page4-footer'>
            <Footer/>
        </div>
    </div>
    </>
  )
}
export default Page4;
import React from 'react';
import "./BasisCard.css";
import { Link } from 'react-router-dom';

const BasisCard=(props)=> {
  return (
    <div className='basiscard-main'>
      <Link to="/projecten">
        <div className='basiscard-image'>
            <img src={props.basisImage} alt=""/>
        </div>
        <p>
            {props.basisPara}
        </p>
      </Link>
    </div>
  )
}

export default BasisCard;
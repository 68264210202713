import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Projecten from "../Pages/Projecten";
import Architectuur from "../Pages/Architectuur";
import Ervaren from "../Pages/Ervaren";
import BasisVan from "../Pages/BasisVan";
import OverOns from "../Pages/OverOns";
import Projecon from "../Pages/Projecon";
import PowerPage from "../Pages/PowerPage";
import ScrollToTop from "../Component/ScrollToTop/ScrollToTop";
import Questions from "../Pages/Questions";
import ContactUS from "../Component/ContactUS/ContactUs";
import Kozijnen from "../Pages/Kozijnen";
import Warmtepompen from "../Component/Warmtepompen/Warmtepompen";
import ProjectenDetail from "../Component/SubComponents/ProjectenDetail/ProjectenDetail";
import IsBouwPdf from "../Component/IsBouwPdf/IsBouwPdf";
import PrivacyPolicy from "../Component/PrivacyPolicy/PrivacyPolicy";

const MainRoutes = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<PowerPage/>} />
        {/* <Route path="/powerpage" element={<PowerPage/>} /> */}
        <Route path="/overons" element={<OverOns/>} />
        <Route path="/allebouwdiensten" element={<BasisVan/>}/>
        <Route path="/projecten" element={<Projecten/>}/>
        <Route path="/allediensten" element={<Architectuur/>}/>
        <Route path="/ervaren" element={<Ervaren/>}/>
        <Route path="/startende" element={<Projecon/>}/>
        <Route path="/question" element={<Questions/>}/>
        <Route path="/contactus" element={<ContactUS/>}/>
        <Route path="/kozijnen" element={<Kozijnen/>}/>
        <Route path="/warmtepompen" element={<Warmtepompen/>}/>
        <Route path="/project/:projectName" element={<ProjectenDetail/>}/>
        <Route path="/isbouwpdf" element={<IsBouwPdf/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>}></Route>
      </Routes>
    </Router>
  );
};

export default MainRoutes;

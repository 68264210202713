import React from 'react';
import "./PowerHeadCard.css";
import { Link } from 'react-router-dom';

const PowerHeadCard = (props) =>{
  return (
    <div className='powerheadcard-main'>
        <div className='powerheadcard-image'>
            <img src={props.powerHeadImage} alt='powerHeadlogo'/>
        </div>
        <div className='powerheadcard-para'>
          {/* <Link to={props.powerheadAnc}>
            {props.powerHeadPara}
          </Link> */}
            <p>
              <Link to={props.powerheadAnc}>
                {props.powerHeadPara}
              </Link>
            </p>
        </div>
    </div>
  )
}

export default PowerHeadCard;
import React from 'react';
import IsBouwMedia from '../Component/IsBouwMedia/IsBouwMedia';
import Footer from '../Component/Footer/Footer';
import worker1 from "../assets/Images/worker1.png";
import worker2 from "../assets/Images/worker2.png";
import "./PowerPage.css";
import WorkerCard from '../Component/SubComponents/WorkerCard/WorkerCard';
import Algemene from '../Component/Algemene/Algemene';
import PowerProject from '../Component/PowerProject/PowerProject';
import Contact from '../Component/Contact/Contact';
import PowerHeadCard from '../Component/SubComponents/PowerHeadCard/PowerHeadCard';
import people1 from "../assets/Images/people1.png";
import people2 from "../assets/Images/people2.png";
import downarrow from "../assets/Images/downhat.png";
import MainHeader from '../Component/MainHeader/MianHeader';
const PowerPage = () => {
  return (
    <>
    <MainHeader/>
    <div className='powerpage-main'>
        <div className='powerHeader'>
            <div className='container'>
                <div className='powerheader-people1'>
                    <PowerHeadCard
                    powerHeadImage={people1}
                    powerheadAnc="/startende"
                    powerHeadPara="Startende ondernemer"/>
                </div>
                <div className='powerheader-people2'>
                    <PowerHeadCard
                    powerHeadImage={people2}
                    powerheadAnc="/ervaren"
                    powerHeadPara="Gevorderde ondernemer"/>
                </div>
            </div>
            <div className='power-downhat'>
                <img src={downarrow} alt="video-logo"/>
            </div>
        </div>
        <div className='container'>
            <div className='power-begin'>
                <h3>
                    Begin nu!
                </h3>
            </div>
            <div className='power-sec'>
                <div className='power-sec-content1'>
                          <div className='power-sec-content-image' data-aos="zoom-in-up" data-aos-duration="3000">
                        <WorkerCard
                        numb="50+"
                        numbText="Mederwerkers"
                        imageCard={worker2}/>
                    </div>
                    <div className='power-sec-content-image' data-aos="zoom-in-up" data-aos-duration="3000">
                        <WorkerCard
                        numb="15+"
                        numbText="Jaar ervaring"
                        imageCard={worker1}/>
                    </div>
                </div>
                      <div className='power-sec-content2' data-aos="fade-up" data-aos-duration="3000">
                    <h4>
                        The power of
                    </h4>
                    <h5>
                        I&SBouw
                    </h5>
                    <p>
                        Kwaliteit en professionaliteit voor een eerlijke prijs. Daar staan wij voor!
                    </p>
                </div>
            </div>
            <div className='power-algemene'>
                <Algemene/>
            </div>
        </div>
        <div className='power-main-project'>
            <PowerProject/>
        </div>
        <div className='power-contact'>
            <Contact/>
        </div>
        <IsBouwMedia/>
        <Footer/>
    </div>
    </>
  )
}

export default PowerPage;
import React from "react";
import MainHeader from "../MainHeader/MianHeader";
import Footer from "../Footer/Footer";
import IsBouwMedia from "../IsBouwMedia/IsBouwMedia";
import "./Warmtepompen.css";
// import bannerImage from "../../assets/Images/warmtepo.png";
import bannerImage from "../../assets/Images/all-electric-warmtepomp.png";
import wImage1 from "../../assets/Images/warmtemp-pic1.png";
import wImage2 from "../../assets/Images/warmtemp-pic2.png";
import wImage3 from "../../assets/Images/warmtemp-pic3.png";
import wImage4 from "../../assets/Images/warmtemp-pic4.png";
import serviceImage1 from "../../assets/Images/service-image-1.png";
import serviceImage2 from "../../assets/Images/service-image-2.png";
import professionalImage from "../../assets/Images/service-man.png";
const Warmtepompen = () => {
  // const sectionArray = [
  //   {
  //     _id: "1",
  //     image: wImage1,
  //     benefit: "Energie-efficiëntie",
  //     description:
  //       "Leg uit hoe onze warmtepompen warmte onttrekken aan natuurlijke bronnen, wat leidt tot een aanzienlijk lager energieverbruik in vergelijking met traditionele verwarmingssystemen.",
  //   },
  //   {
  //     _id: "2",
  //     image: wImage2,
  //     benefit: "Milieu vriendelijkheid",
  //     description:
  //       "Benadruk de positieve impact van de keuze voor de warmtepompen van IS Bouw bij het verminderen van de CO2-uitstoot, wat bijdraagt aan een groenere en duurzamere toekomst.",
  //   },
  //   {
  //     _id: "3",
  //     image: wImage3,
  //     benefit: "Kostenbesparingen",
  //     description:
  //       "Leg uit hoe onze warmtepompen warmte onttrekken aan natuurlijke bronnen, wat leidt tot een aanzienlijk lager energieverbruik in vergelijking met traditionele verwarmingssystemen.",
  //   },
  //   {
  //     _id: "4",
  //     image: wImage4,
  //     benefit: "Optimaal draagcomfort",
  //     // description:
  //     //   "Beschrijf de constante en aangename temperatuur die onze betrouwbare warmtepompoplossingen bieden, waardoor comfort het hele jaar door gegarandeerd is.",
  //     description:"",
  //   },
  // ];
  const sectionArray = [
    {
      _id: "1",
      image: wImage1,
      benefit: "Energie-efficiëntie",
    },
    {
      _id: "2",
      image: wImage2,
      benefit: "Milieu vriendelijkheid",
    },
    {
      _id: "3",
      image: wImage3,
      benefit: "Kostenbesparingen",
    },
    {
      _id: "4",
      image: wImage4,
      benefit: "Optimaal draagcomfort",
    },
  ];

  return (
    <>
      <MainHeader />
      <div className="warmtepompen-banner">
        <div className="warmtepompen-banner-content">
          <h1>Warmtepompen</h1>
          <p>
            Welkom bij IS Bouw! Energiekosten verlagen? Kies milieubewust &
            efficiënt met onze warmtepompen!
          </p>
        </div>
        <div className="warmtepompen-banner-image">
          <img src={bannerImage} alt="banner-logo" />
        </div>
      </div>

      <div className="warmtepompen-main-heading">
        <div className="warmtepompen-main-heading-content">
          <h1>Waarom kiezen voor warmtepompen van IS Bouw?</h1>
          <p>
            Ontdek de kracht van warmtepompen van IS Bouw en verlaag uw
            energiekosten duurzaam.
          </p>
        </div>
      </div>

      <div className="warmtepompen-section">
        {sectionArray.map((data) => {
          return (
            <div
              className="warmtepompen-section-content"
              key={data._id}
              data-aos="flip-right"
              data-aos-duration="3000"
            >
              <img src={data.image} alt="logo-data" />
              <p>{data.benefit}</p>
              {/* <h5>{data.description.slice(0, 35)}... </h5> */}
            </div>
          );
        })}
      </div>

      <div className="warmtepompen-seperator"></div>
      <div className="warmtepompen-professional">
        <div className="warmtepompen-professional-image">
          <img src={professionalImage} alt="logo-service" />
        </div>

        <div className="warmtepompen-professional-content">
          <h1>Professionele installatie</h1>
          <p>
            Verzeker de klant van een naadloze ervaring met ons ervaren team en
            garandeer een professioneel en efficiën warmtepompinstallatieproces.
          </p>
          {/* <div className="warmtepompen-professional-content-sections"> */}
          <div className="warmtepompen-professional-content-section">
            <div className="warmtepompen-professional-content-section-image">
              <img src={serviceImage1} alt="logo-service" />
            </div>
            <div className="warmtepompen-professional-content-section-content">
              <h5>Naadloos proces</h5>
              <p>
                We hebben een team van experts die de installatie van uw
                warmtepomp met de grootst mogelijke professionaliteit en
                efficiëntie zullen uitvoeren.
              </p>
            </div>
          </div>
          <div className="warmtepompen-professional-content-section">
            <div className="warmtepompen-professional-content-section-image">
              <img src={serviceImage2} alt="logo-service" />
            </div>
            <div className="warmtepompen-professional-content-section-content">
              <h5>Maximaliseer efficiëntie</h5>
              <p>
                Professionele installatie zorgt voor maximale efficiëntie en
                prestaties van uw warmtepompsysteem.
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>

      <IsBouwMedia />
      <Footer />
    </>
  );
};

export default Warmtepompen;

import React from 'react';
import ProjectenCard from '../SubComponents/ProjectenCard/ProjectenCard';
import Pro1 from "../../assets/Images/pro1.png";
import Pro2 from "../../assets/Images/pro2.png";
import Pro3 from "../../assets/Images/pro3.png";
import "./ProjectenList.css";
const ProjectenList = () => {
  return (
    <div className='container'>
      <div className='projectenlist-main'>
        <div className='projectenlist-sec' data-aos="fade-up"
          data-aos-anchor-placement="center-bottom" data-aos-duration="3000">
          <ProjectenCard
            ProImage={Pro1}
            ProHead="Projecten"
            ProLink="/projecten" />
        </div>
        <div className='projectenlist-sec' data-aos="fade-up"
          data-aos-anchor-placement="center-bottom" data-aos-duration="3000">
          <ProjectenCard
            ProImage={Pro2}
            ProHead="Expertisise"
            ProLink="/allediensten" />
        </div>
        <div className='projectenlist-sec' data-aos="fade-up"
          data-aos-anchor-placement="center-bottom" data-aos-duration="3000">
          <ProjectenCard
            ProImage={Pro3}
            ProHead="Neem contact op"
            ProLink="" />
        </div>
      </div>
    </div>
  )
}
export default ProjectenList;
import React from 'react';
import BasisCard from '../SubComponents/BasisCard/BasisCard';
import basis1 from "../../assets/Images/basis1.png";
import basis2 from "../../assets/Images/basis2.png";
import basis3 from "../../assets/Images/basis3.png";
import basis4 from "../../assets/Images/basis4.png";
import "./BasisCardList.css";

const BasisCardList = () => {
    return (
        <div className='container'>
            <div className='basiscardlist-main'>
                <div className='basiscardlist-heading'>
                    <h4>
                        I&S Bouw
                    </h4>
                </div>
                <div className='basiscardlist-sec'>
                    <div className='basiscardlist-content1' data-aos="fade-up" data-aos-duration="3000">
                        <BasisCard
                            basisImage={basis1}
                            basisPara="Nieuwbouw" />
                    </div>
                    <div className='basiscardlist-content2'>
                        <div className='basiscardlist-content2-data' data-aos="flip-right" data-aos-duration="3000">
                            <BasisCard
                                basisImage={basis2}
                                basisPara="Renovatiebouw" />
                        </div>
                        <div className='basiscardlist-content2-data' data-aos="flip-right" data-aos-duration="3000">
                            <BasisCard
                                basisImage={basis3}
                                basisPara="Transformatiebouw" />
                        </div>
                    </div>
                    <div className='basiscardlist-content3' data-aos="fade-up" data-aos-duration="3000">
                        <BasisCard
                            basisImage={basis4}
                            basisPara="Bedrijfsgebouwen" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BasisCardList;
import React from 'react';
import "./StaatPara.css";
const StaatPara = (props) =>{
  return (
    <div className='staatpara-main'>
        <div className='container'>
            <div className='staatpara-sec'>
                <div className='staatpara1'>
                    <p>
                        {props.staatpara1}
                    </p>
                </div>
                <div className='staatpara2'>
                    <p>
                        {props.staatpara2}
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
export default StaatPara;
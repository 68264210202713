import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { Link } from 'react-router-dom';
import "./Footer.css";
const Footer = () => {
  return (
    <div className='container'>
        <div className='footer-main'>
            <div className='footer-sec1 sec1-width'>
                <h3>
                    Adresgegevens
                </h3>
                <div className='footer-location'>
                    <div className='location-icon'>
                        <LocationOnIcon/>
                    </div>
                    <span>
                        Weena 505 <br/>
                        3013 AL, Rotterdam
                    </span>
                </div>
                <div className='footer-tel'>
                    <div className='telephone-icon'>
                        <PhoneIphoneIcon/>
                    </div>
                    <span>
                        <p>Tel: <a href="tel:085 800 0824">085 800 0824</a></p>
                        {/* <p>Tel: <a href="tel:+3101675606">+31(0)167 56 06</a></p> */}
                    </span>
                </div>
            </div>
            <div className='footer-sec1 sec2-width'>
                <h3>
                    <Link to="/allediensten">
                        Expertises
                    </Link>
                </h3>
                <div className='footer-list'>
                    <ul>
                        <li>
                            <Link to="/projecten">
                                Nieuwbouw
                            </Link>
                        </li>
                        <li>
                            <Link to="/projecten">
                                Bedrijfsgebouwen
                            </Link>
                        </li>
                        <li>
                            <Link to="/projecten">
                                Transformatie Projecten
                            </Link>
                        </li>
                        <li>
                            <Link to="/projecten">
                                Renovatie Projecten
                            </Link>
                        </li>
                        <li>
                            <Link to="/privacypolicy">
                                Privacy Policy
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='footer-sec1 sec3-width'>
                <h3>
                    Quick Links
                </h3>
                <div className='footer-list1'>
                    <ul>
                        <li>
                            <Link to="/">
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link to="/projecten">
                                Projecten
                            </Link>
                        </li>
                        <li>
                            <Link to="/overons">
                                Over Ons
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}
export default Footer;
import React from 'react';
import StaatPara from '../Component/SubComponents/StaatPara/StaatPara';
import IsBouwMedia from '../Component/IsBouwMedia/IsBouwMedia';
import Footer from '../Component/Footer/Footer';
import BasisCardList from '../Component/BasisCardList/BasisCardList';
import "./BasisVan.css";
import MainHeader from '../Component/MainHeader/MianHeader';

const BasisVan = () => {
  return (
    <>
    <MainHeader/>
    <div className='basisvan-mian'>
      <div className='basisvan-head'>
        <div className='container'>
          <p>
            — I&SBouw
          </p>
          <span>
            BOUWEN OP BASIS VAN
          </span>
        </div>
      </div>
        <div className='basisvan-sec1'>
            <StaatPara
            staatpara1="I&SBouw combineert een traditionele bouwmethode met een moderne aanpak. Door het samenvoegen van het
            beste van beide werelden bouwen we op een andere, verbeterde en vooral ook financieel aantrekkelijke manier."
            staatpara2="Wij zijn ervan overtuigd dat onze werkwijze verder gaat waar anderen stoppen. Op deze manier kunnen
            we de klant beter bedienen en zijn we in staat om samen met onze ketenpartners elk project succesvol af te ronden."/>
        </div>
        <div className='basisvan-sec2'>
            <BasisCardList/>
        </div>
        <IsBouwMedia/>
        <Footer/>
    </div>
    </>
  )
}

export default BasisVan; 
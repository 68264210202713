import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import one from "../../../assets/Images/Untitled-design.png";
import four from "../../../assets/Images/Untitled-design-1.png";
import two from "../../../assets/Images/Untitled-design-2.png";
import three from "../../../assets/Images/Untitled-design-3.png";
import five from "../../../assets/Images/Untitled-design-5.png";
import six from "../../../assets/Images/Untitled-design-6.png";
import seven from "../../../assets/Images/Untitled-design-7.png";
import eight from "../../../assets/Images/Untitled-design-8.png";
import nine from "../../../assets/Images/Untitled-design-4.png";
import ten from "../../../assets/Images/Untitled-design-9.png";
import downHat from "../../../assets/Images/downhat.png";
import upHat from "../../../assets/Images/uphat.png";
import "./ProjectenImage.css";

const ProjectenImage = () => {
    const [showHiddenImages, setShowHiddenImages] = useState(false);
    const handleButtonShow = () => {
      setShowHiddenImages(true);
    };
    const handleButtonHide = () => {
        setShowHiddenImages(false);
      };
  return (
    <>
    <div className='projectImagesec-main'>
        <div className='projectimagesec1'>
            <div className='projectsec1-content1'>
                <div className='project-sec1-contnet1-data image-border image-container'>
                    <Link to ="/project/locatie-Zuid-Holland-afgerond-pf">
                        <img src={eight} alt="logo"/>
                        <div className="middle"><p>Locatie-Zuid-Holland-afgerond</p></div>
                    </Link>
                </div>
                <div className='project-sec1-contnet1-data-sub1 image-border image-container'>
                    <Link to ="/project/locate-Limburg-afgerond-pe">
                        <img src={two} alt="logo1"/>
                        <div className="middle"><p>Locate-Limburg-afgerond</p></div>
                    </Link>
                </div>
                <div className='project-sec1-contnet1-data-sub2 image-border image-container'>
                    <Link to ="/project/locate-Limburg-afgerond-pc">
                        <img src={three} alt="logo1"/>
                        <div className="middle"><p>locate-Limburg-afgerond</p></div>
                    </Link>
                </div>
            </div>
            <div className='projectsec1-content2 image-border image-container'>
                <Link to ="/project/locate-Limburg-ongoing-pb">
                    <img src={four} alt="logo"/>
                    <div className="middle"><p>locate-Limburg-ongoing</p></div>
                </Link>
            </div>
        </div> 
        {showHiddenImages?
        <>
            <div className='projectimagesec1'>
                <div className='projectsec1-content1'>
                    <div className='project-sec1-contnet1-data-sub1 image-border image-container'>
                        <Link to ="/project/Locatie-Zuid-Holland2-ongoing(transformatie)pj">
                            <img src={five} alt="logo1"/>
                            <div className="middle"><p>Locatie-Zuid-Holland2-ongoing(transformatie)</p></div>
                        </Link>
                    </div>
                    <div className='project-sec1-contnet1-data-sub2 image-border image-container'>
                        <Link to ="/project/Locatie-Zuid-Holland-ongoing(transformatie)-pi">
                            <img src={six} alt="logo1"/>
                            <div className="middle"><p>Locatie-Zuid-Holland-ongoing(transformatie)</p></div>
                        </Link>
                    </div>
                    <div className='project-sec1-contnet1-data image-border image-container'>
                        <Link to ="/project/Locatie-Zuid-Holland-afgerond(renovatie)-pg">
                            <img src={one} alt="logo1"/>
                            <div className="middle"><p>Locatie-Zuid-Holland-afgerond(renovatie)</p></div>
                        </Link>
                    </div>
                </div>
                <div className='projectsec1-content2 image-border image-container'>
                    <Link to ="/project/locate-Limburg-ongoing-pd">
                        <img src={seven} alt="logo1"/>
                        <div className="middle"><p>Locate-Limburg-ongoing</p></div>
                    </Link>
                </div>
            </div>
            <div className='projectimagesec1'>
                <div className='projectsec1-content1'>
                    <div className='project-sec1-contnet1-data-sub1 image-border image-container'>
                        <Link to ="/project/locate-Limburg-afgerond-pa">
                            <img src={nine} alt="logo1"/>
                            <div className="middle"><p>Locate-Limburg-afgerond</p></div>
                        </Link>
                    </div>
                    <div className='project-sec1-contnet1-data-sub2 image-border image-container'>
                        <Link to ="/project/locatie-Zuid-Holland-afgerond(transformatie3)-ph">
                            <img src={ten} alt="logo1"/>
                            <div className="middle"><p>Locatie-Zuid-Holland-afgerond(transformatie3)</p></div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
        :
        <div></div>}
        </div>
        {!showHiddenImages?
        <div className='powerproject-downarrow text-center mb-4' onClick={handleButtonShow}>
            <img src={downHat} alt ="downarrow-logo"/>
        </div>:
        <div className='powerproject-downarrow text-center mb-4' onClick={handleButtonHide}>
            <img src={upHat} alt ="downarrow-logo"/>
        </div>
        }
    </>
  )
}

export default ProjectenImage;
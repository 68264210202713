import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import logo from "../../assets/Images/islogo.png";
import "./MainHeader.css";
const MainHeader =()=> {
  return (
    <div className='mainheader-bg'>
        <Navbar sticky="top" expand="lg">
        <Container>
            <Navbar.Brand><Link to="/"><div className='headlogo-image header-relative'><img src={logo} alt="logo-tag"/></div></Link></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll"/>
            <Navbar.Collapse id="navbarScroll" className='collapse-abs'>
            <Nav className="me-auto my-lg-0 navbar-data">
                <div className='mainhead-navigators'>
                    <div className='mainhead-link1'>
                        <Link to="/">Home</Link>
                    </div>
                    <div className='mainhead-linkdropdown'>
                                  <NavDropdown title="Expertise" id="navbarScrollingDropdown" className='mainhead-link1'>
                            <div className='mainhead-item'>
                                <NavDropdown.Item>
                                    <ul>
                                        <li>
                                            <Link to="/allediensten">
                                                Alle Expertises
                                            </Link>
                                        </li>
                                    </ul>
                                </NavDropdown.Item>
                            </div>
                            <div className='mainhead-item'>
                                <NavDropdown.Item>
                                    <ul>
                                        <li>
                                            <Link to="/warmtepompen">
                                                Warmtepompen
                                            </Link>
                                        </li>
                                    </ul>
                                </NavDropdown.Item>
                            </div>
                            <div className='mainhead-item'>
                                <NavDropdown.Item>
                                    <ul>
                                        <li>
                                            <Link to="/kozijnen">
                                                Kozijnen
                                            </Link>
                                        </li>
                                    </ul>
                                </NavDropdown.Item>
                            </div>
                            <div className='mainhead-item'>
                                <NavDropdown.Item>
                                    <ul>
                                        <li>
                                            <Link to="/allebouwdiensten">
                                                Alle Bouw Expertises
                                            </Link>
                                        </li>
                                    </ul>
                                </NavDropdown.Item>
                            </div> 
                        </NavDropdown>
                        {/* <Link to="#">Exepertise</Link> */}
                    </div>
                    <div className='mainhead-link1'>
                        <Link to="/projecten">Projecten</Link>
                    </div>
                    <div className='mainhead-link1'>
                        <Link to="/overons">Over ons</Link>
                    </div>
                </div>
            </Nav>
            
            <div className='mainhead-button'>
                <Link to="/contactus">Contact</Link>
            </div>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    </div>
  );
}
export default MainHeader;
import React from 'react';
import "./ProjectenLink.css";
import { Link } from 'react-router-dom';

const ProjectenLink = (props) => {
  return (
    <div className='projectenlink-main'>
        <h3>
            {props.projectHead}
        </h3>
        <Link to={props.projectlink1}>{props.projecttext1}</Link>
        <span> / </span> 
        <Link to={props.projectlink2}>{props.projecttext2}</Link> 
    </div>
  )
}

export default ProjectenLink;
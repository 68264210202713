import React from 'react';
import MainHeader from '../../MainHeader/MianHeader';
import IsBouwMedia from '../../IsBouwMedia/IsBouwMedia';
import Footer from '../../Footer/Footer';
import ProjectenLink from '../ProjectenLink/ProjectenLink';
import { useParams } from 'react-router-dom';
import "./ProjectenDetail.css";
import Content from "../../../assets/data/impala_data.json";

const ProjectenDetail=()=> {
    let {projectName}  =useParams();
    const imageContext = require.context('../../../assets/Images/project1', false, /\.(jpg|jpeg|png|gif|svg|JPG)$/);
    const allImagePaths = imageContext.keys();
    const filteredImagePaths = allImagePaths.filter((path) => path.includes(projectName));
    let projectTitle = projectName.split("-p")[0].replace("-"," ");
    const descriptions = Content.map(item => {
        if (item.name === projectName) {
          return item.description;
        }
        return null;
      });
console.log(filteredImagePaths)
  return (
    <>
    <MainHeader/>
    <ProjectenLink
     projectHead={projectTitle}
     projectlink1="#"
     projecttext1="Home"
     projectlink2="#"
     projecttext2="Portfolio"
    />
    <div className='projectendetail-para'>
      <p>
        {descriptions}
      </p>
    </div>
    <div className='projectendetail-main'> 
      {filteredImagePaths.map((path, index) => (
          <div className='projectendetail-images'>
              <img
                  key={index}
                  src={imageContext(path)}
                  alt={`detail-logo`}
              />
          </div>
      ))}
    </div>
    <IsBouwMedia/>
    <Footer/>
    </>
  )
}

export default ProjectenDetail;
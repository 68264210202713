import React from 'react';
import { useState } from 'react';
import ProjectenPara from '../Component/SubComponents/ProjectenPara/ProjectenPara';
import IsBouwMedia from '../Component/IsBouwMedia/IsBouwMedia';
import Footer from '../Component/Footer/Footer';
import HeadParaBorder from '../Component/SubComponents/HeadParaBorder/HeadParaBorder';
import builder from "../assets/Images/builder.png";
import MainHeader from '../Component/MainHeader/MianHeader';
import "./Projecon.css";
import ButtonComp from '../Component/SubComponents/ButtonComp/ButtonComp';
import BtnSpan from '../Component/SubComponents/ButtonComp/BtnSpan';
import { Link } from 'react-router-dom';
const Projecon = ()=> {
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [answers, setAnswers] = useState([]); 
    const [titleShow,setTitleShow] = useState(false);
    const [sectionShow,setSectionShow] = useState(false);
    const handleNextQuestion =  (response) => {
      setAnswers((prevAnswers) => [...prevAnswers, response]);
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    };
    const handleCompleteQuestion = () => {
        setCurrentQuestion(1);
        const allTrue = answers.every(value => value === true);
        setTitleShow(allTrue);
        setSectionShow(true);
        setAnswers([]);
    };
  return (
    <>
    <MainHeader/>
    <div className='projecon-main'>
        <div className='projecon-head'>
            <ProjectenPara
            // projectheading="Begeninnende Projecon wikkelaarBeginnende Projectontwikkelaar"
            projectheading="Beginnende Projectontwikkelaar"
            projectparas="Beginnende Projectontwikkelaar"/>
        </div>
        <div className='container'>
            <div className='projecon-headpara'>
                <HeadParaBorder
                hphead="Beginnende Projectontwikkelaar:"
                hppara="Bent u een beginnende projectontwikkelaar en weet u nog niet helemaal zeker wat uw mogelijkheden zijn? 
                Volg het stappenplan en laten we beginnen!"
                hpspan=""/>
            </div>
            <div className='projecon-button'>
                {/* <ListButton/> */}
                <div className='listbutton-main'>
                    {currentQuestion === 1 && (
                    <div className='listbutton-content1'>
                        <p>⦁     Is het object winstgevend?</p>
                        <div className='listbutton-content1-button'>
                            <div className='content1-button1' onClick={() => handleNextQuestion(true)}>
                                <BtnSpan
                               linkDataText="ja"/>
                            </div>
                            <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                                <BtnSpan
                                linkDataText="Ik weet het niet"/>
                            </div>
                            <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                                <BtnSpan
                                 linkDataText="nee"/>
                            </div>
                        </div>
                    </div>
                    )}
                    {currentQuestion === 2 && (
                    <div className='listbutton-content1'>
                        <p>⦁     Weet u hoe u het project gaat financieren?</p>
                        <div className='listbutton-content1-button'>
                            <div className='content1-button1' onClick={() => handleNextQuestion(true)}>
                                <BtnSpan
                                 linkDataText="ja"/>
                            </div>
                            <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                                <BtnSpan
                                linkDataText="Ik weet het niet"/>
                            </div>
                            <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                                <BtnSpan
                                linkDataText="nee"/>
                            </div>
                        </div>
                    </div>
                    )}
                    {currentQuestion === 3 && (
                    <div className='listbutton-content1'>
                        <p>⦁     Heeft u uw vergunning in orde?</p>
                        <div className='listbutton-content1-button'>
                            <div className='content1-button1' onClick={() => handleNextQuestion(true)}>
                                <BtnSpan
                                linkDataText="ja"/>
                            </div>
                            <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                                <BtnSpan
                                linkDataText="Ik weet het niet"/>
                            </div>
                            <div className='content1-button1' onClick={() => handleNextQuestion(false)}>
                                <BtnSpan
                                linkDataText="nee"/>
                            </div>
                        </div>
                    </div>
                    )}
                    {currentQuestion === 4 && (
                    <div>
                        <h2>Survey Completed!</h2>
                        <p>Thank you for participating in the survey.</p>
                        <div className='btn-done' onClick={() =>handleCompleteQuestion()}>
                            <ButtonComp
                            linktext="Done"/>
                        </div>
                    </div>
                    )} 
                </div>
            </div>
            <div className='projecon-builder-main'>
                <div className='projecon-builder'>
                    <img src={builder} alt='builder-logo'/>
                </div>
                {sectionShow ? 
                <div>
                {titleShow ?
                    <div className='builder-btn1'>
                        <Link to="/contactus">
                            <ButtonComp
                            linktext="Plan een kennismakingsgesprek in"
                            linkdata="#"/>
                        </Link>
                    </div> 
                :
                    <div className='builder-btn2'>
                        <ButtonComp
                        linktext="Plan een adviesgesprek in"
                        linkdata="#"/>
                    </div>
                 } 
                </div>
                :<div>
                </div>}
            </div>
        </div>
        <IsBouwMedia/>
        <Footer/>
    </div>
    </>
  )
}
export default Projecon;
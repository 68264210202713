import React from 'react';
import StaatPara from '../Component/SubComponents/StaatPara/StaatPara';
import IsBouwMedia from '../Component/IsBouwMedia/IsBouwMedia';
import Footer from '../Component/Footer/Footer';
import ProjectenList from '../Component/ProjectenList/ProjectenList';
import PlatteList from '../Component/PlatteList/PlatteList';
import "./OverOns.css";
import Kracht from '../Component/Kracht/Kracht';
import MainHeader from '../Component/MainHeader/MianHeader';
import CeoSection from '../Component/CeoSection/CeoSection';
const OverOns = () => {
  return (
    <>
    <MainHeader/>
    <div className='overons-main'>
      <div className='overons-head'>
        <div className='container'>
          <p>
            OVER ONS
          </p>
        </div>
      </div>
      <div className='overons-para'>
          <StaatPara
          staatpara1="Professionaliteit en kwaliteit staat bij ons op nummer 1. Bij I&SBouw wordt dit ook ook erkend door onze ervaring 
          en relaties met onze klanten."
          staatpara2="Wij zijn er van overtuigt dat onze werkwijze verder gaat waar andere stoppen, zo bedienen we de klant beter en 
          kunnen we samen met onze ketenpartners ieder project succesvol opleveren."/>
      </div>
      <div className='overons-kracht'>
        <Kracht/>
      </div>
      <div className='overons-sec1'>
          <PlatteList/>
      </div>
      <div>
        <CeoSection/>
      </div>
      <div className='overons-sec2'>
          <ProjectenList/>
      </div>
      <IsBouwMedia/>
      <Footer/>
    </div>
    </>
  )
}

export default OverOns;
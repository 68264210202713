import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import pro1 from "../../assets/Images/Untitled-design.png";
import pro2 from "../../assets/Images/Untitled-design-8.png";
import pro3 from "../../assets/Images/Untitled-design-7.png";
import pro4 from "../../assets/Images/Untitled-design-2.png";
import pro5 from "../../assets/Images/Untitled-design-3.png";
import pro7 from "../../assets/Images/Untitled-design-1.png"
import pro8 from "../../assets/Images/Untitled-design-4.png"
import pro9 from "../../assets/Images/Untitled-design-5.png"
import pro6 from "../../assets/Images/Untitled-design-6.png"
import pro10 from "../../assets/Images/Untitled-design-9.png"
import prodown from "../../assets/Images/downhat.png";
import uphat from "../../assets/Images/uphat.png";
import "./PowerProject.css";

const PowerProject = () => {
    const [showHiddenImages, setShowHiddenImages] = useState(false);
    const handleButtonShow = () => {
      setShowHiddenImages(true);
    };
    const handleButtonHide = () => {
        setShowHiddenImages(false);
      };
    
  return (
    <div className='powerproject-main'>
        <h4>
            Our Projects
        </h4>
        <div className='powerImages-main'>
            <div className='powerproject-images1'>
                <div className='powerproject-image-sec1'>
                    <div className='image-sec1-image1 pt-4 image-border image-container'>
                        <Link to="/project/Locatie-Zuid-Holland-afgerond(renovatie)-pg">
                            <img src={pro1} alt='project-logo'/>
                            <div className="middle"><p>Locatie-Zuid-Holland-afgerond(renovatie)</p></div>
                        </Link>
                    </div>
                    <div className='image-sec1-image1 pt-4 image-border image-container'>
                        <Link to="/project/locatie-Zuid-Holland-afgerond-pf">
                            <img src={pro2} alt='project-logo'/>
                            <div className="middle"><p>Locatie-Zuid-Holland-afgerond</p></div>
                        </Link>
                    </div>
                </div>
                <div className='powerproject-image-sec2 image-border my-auto image-container'>
                    <Link to="/project/locate-Limburg-ongoing-pd">
                        <img src={pro3} alt='project-logo'/>
                        <div className="middle"><p>Locate-Limburg-ongoing</p></div>
                    </Link>
                </div>
            </div>
            <div className='powerproject-images2'>
                <div className='image-sec2-image1 image-border image-container'>
                    <Link to="/project/locate-Limburg-afgerond-pe">
                        <img src={pro4} alt='project-logo'/>
                        <div className="middle"><p>Locate-Limburg-afgerond</p></div>
                    </Link>
                </div>
                <div className='image-sec2-image1 image-border image-container'>
                    <Link to="/project/locate-Limburg-afgerond-pc">
                        <img src={pro5} alt='project-logo'/>
                        <div className="middle"><p>Locate-Limburg-afgerond</p></div>
                    </Link>
                </div>
                <div className='image-sec2-image1 image-border image-container'>
                    <Link to="/project/Locatie-Zuid-Holland-ongoing(transformatie)-pi">
                        <img src={pro6} alt='project-logo'/>
                        <div className="middle"><p>Locatie-Zuid-Holland-ongoing(transformatie)</p></div>
                    </Link>
                </div>
            </div>
            {showHiddenImages?
            <div className='powerproject-images2 mt-4'>
                <div className='image-sec2-image1 image-border image-container'>
                    <Link to="/project/locate-Limburg-ongoing-pb">
                        <img src={pro7} alt='project-logo'/>
                        <div className="middle"><p>Locate-Limburg-ongoing</p></div>
                    </Link>
                </div>
                <div className='another-sec'>
                    <div className='image-sec2-image1 image-border sec-image image-container'>
                        <Link to="/project/locate-Limburg-afgerond-pa">
                            <img src={pro8} alt='project-logo'/>
                            <div className="middle"><p>Locate-Limburg-afgerond</p></div>
                        </Link>
                    </div>
                    <div className='image-sec2-image1 image-border sec-image image-container'>
                        <Link to="/project/Locatie-Zuid-Holland2-ongoing(transformatie)pj">
                            <img src={pro9} alt='project-logo'/>
                            <div className="middle"><p>Locatie-Zuid-Holland2-ongoing(transformatie)</p></div>
                        </Link>
                    </div>
                    <div className='image-sec2-image1 image-border sec-image image-container'>
                        <Link to="/project/locatie-Zuid-Holland-afgerond(transformatie3)-ph">
                            <img src={pro10} alt='project-logo'/>
                            <div className="middle"><p>Locatie-Zuid-Holland-afgerond(transformatie3)</p></div>
                        </Link>
                    </div>
                </div>
            </div>:
            <div></div>}
        </div>
        {!showHiddenImages?
        <div className='powerproject-downarrow text-center mt-3' onClick={handleButtonShow}>
            <img src={prodown} alt ="downarrow-logo"/>
        </div>:
        <div className='powerproject-downarrow text-center mt-3' onClick={handleButtonHide}>
            <img src={uphat} alt ="downarrow-logo"/>
        </div>
        }
    </div>
  )
}

export default PowerProject;
import React from 'react';
import ProjectenPara from '../Component/SubComponents/ProjectenPara/ProjectenPara';
import HeadParaBorder from '../Component/SubComponents/HeadParaBorder/HeadParaBorder';
import erv from "../assets/Images/Ervaren.png";
import "./Ervaren.css";
import IsBouwMedia from '../Component/IsBouwMedia/IsBouwMedia';
import Footer from '../Component/Footer/Footer';
import MainHeader from '../Component/MainHeader/MianHeader';
import { Link } from 'react-router-dom';
const Ervaren = () => {
  return (
    <>
    <MainHeader/>
    <div className='ervaren-mian'>
        <div className='ervaren-head'>
            <ProjectenPara
            projectheading="Ervaren ondernemer"
            projectparas="Ervaren Projectontwikkelaar"/>
        </div>
        <div className='ervaren-headpara'>
            <HeadParaBorder
            hphead="Ervaren ondernemer"
            hppara="Bent u een ervaren projectontwikkelaar en wilt u werken met een betrouwbaar en professioneel team van experts?"
            hpspan=""/>
            <div className='ervaren-logo-content1'>
                <Link to="/contactus"><p>Dan ontmoeten wij u graag voor een kennismakingsgesprek!</p></Link>
            </div>
        </div>
        <div className='ervaren-logo'>
            <img src={erv} alt='ervaren-logo'/>
            {/* <div className='ervaren-logo-content'>
                <p>Dan ontmoeten wij u graag voor een kennismakingsgesprek!</p>
            </div> */}
        </div>
        <IsBouwMedia/>
        <Footer/>
    </div>
    </>
  )
}

export default Ervaren;
import React from 'react';
import "./ButtonComp.css";
const ButtonComp = (props) => {
  return (
    <div className='buttoncomp-main'>
        {/* <Link to={props.linkdata}>{props.linktext}</Link> */}
        <h3>
            {props.linktext}         
        </h3>
    </div>
  )
}
export default ButtonComp;
import MianRoutes from "./Navigation/MainRoutes";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"; 
function App() {
  return (
    <div className="App">
      <MianRoutes/>
    </div>
  );
}
export default App;

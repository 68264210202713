import React from "react";
import "./ContactUs.css";
import MainHeader from "../MainHeader/MianHeader";
import Footer from "../Footer/Footer";
import Logo from "../../assets/Images/grey-logo.png";
import bannerImage from "../../assets/Images/contactus-banner-bg.png";
import bannerLogo from "../../assets/Images/blacklogo.png";
const ContactUS = () => {
  return (
    <>
      <MainHeader />
      <div className="contact-us-content">
        <h1>CONTACT US</h1>
        <p>Nog vragen? Voel je vrij om contact met ons op te nemen</p>
      </div>

      <div className="container contact-us-input-logo">
        <div className="contact-us-input-fields">
          <div className="contact-us-input-email-fields">
            <textarea id="name" value="" placeholder="Name" />
            <textarea id="email" value="" placeholder="Email Address" />
          </div>

          <div className="contact-us-message-field">
            <textarea id="message" value="" placeholder="Message" rows={8} />
          </div>

          <button className="btn btn-success contact-us-send-button">
            Send
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="41"
              viewBox="0 0 25 41"
              fill="none"
            >
              <path
                d="M2 38L20.0977 23.2842C22.0939 21.6611 22.0607 18.6028 20.0299 17.0233L2 3"
                stroke="#342525"
                stroke-width="6"
              />
            </svg>
          </button>
        </div>
        <div className="contact-us-logo">
          <img src={Logo} alt="logo-here" width="100%" height="100%" />
        </div>
      </div>
      <div className="contact-us-main">
        <div className="contact-us-image">
          <img src={bannerImage} alt="banner-logo"/>
        </div>
        <div className="contact-us-banner-main">
          <div className="contact-us-banner-image">
            <img
              src={bannerLogo}
              alt="banner-logo"
            />
          </div>
          <div className="contact-us-banner-content mt-2">
            <p>ISBouw</p>
            <p>Weena 505</p>
            <p>The Netherlands</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUS;

import React from 'react';
import MainHeader from '../MainHeader/MianHeader';
import Footer from '../Footer/Footer';
import IsBouwMedia from '../IsBouwMedia/IsBouwMedia';
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
    <MainHeader/>
    <div className='container'>
        <div className='privacypolicy-main'>
            <h3>
                Privacybeleid
            </h3>
            <h6>
                Laatste Update: 22-11-2023
            </h6>
            <p>
                I&SBouw begrijpt dat uw privacy belangrijk voor u is. We doen er alles aan om de privacy van uw persoonlijk identificeerbare 
                informatie te beschermen terwijl u deze website gebruikt. Dit privacybeleid vertelt u hoe we de informatie die we van u 
                verzamelen beschermen en gebruiken. Door deze website te gebruiken, gaat u akkoord met de voorwaarden beschreven in de
                meest recente versie van dit privacybeleid. U dient ook onze gebruiksvoorwaarden te lezen om de algemene regels over uw 
                gebruik van deze website te begrijpen, en eventuele aanvullende voorwaarden die van toepassing kunnen zijn wanneer u 
                toegang krijgt tot bepaalde diensten of materialen op bepaalde delen van deze website. "Wij" betekent "onze" betekent
            </p>
            <p>
                I&SBouw en haar dochterondernemingen. "U", "uw", bezoeker "of" gebruiker "betekent de persoon die deze site bezoekt.
            </p>
            <h4>
                PERSOONLIJKE EN NIET-PERSOONLIJKE INFORMATIE
            </h4>
            <p>
                Ons privacybeleid beschrijft hoe we omgaan met uw persoonlijke en niet-persoonlijke informatie.
            </p>
            <h4>
                WAT IS NIET-PERSOONLIJKE INFORMATIE EN HOE WORDT HET VERZAMELD EN GEBRUIKT?
            </h4>
            <p>
                Niet-persoonlijke informatie is informatie die u niet kan identificeren. Als u deze website bezoekt om informatie te lezen, 
                zoals informatie over een van onze diensten, kunnen we bepaalde niet-persoonlijke informatie over u verzamelen via de 
                webbrowser van uw computer. Omdat niet-persoonlijke informatie u niet kan identificeren of op enigerlei wijze aan u kan 
                worden gekoppeld, zijn er geen beperkingen op hoe we niet-persoonlijke informatie kunnen gebruiken of delen. Wat is 
                persoonlijke informatie en hoe wordt deze verzameld? Persoonlijke informatie is informatie die u als individu identificeert, 
                zoals uw naam, postadres, e-mailadres, telefoonnummer en faxnummer. We kunnen op verschillende manieren persoonlijke 
                informatie van u verzamelen: • Wanneer u ons een aanvraag of een ander formulier stuurt • Wanneer u een transactie uitvoert 
                met ons, onze gelieerde ondernemingen of anderen • Wanneer we informatie over u verzamelen om een transactie te ondersteunen, 
                zoals een creditcard informatie .
            </p>
            <h4>
                WORDEN COOKIES OF ANDERE TECHNOLOGIEËN GEBRUIKT OM PERSOONLIJKE INFORMATIE TE VERZAMELEN?
            </h4>
            <p>
                Ja, we kunnen cookies en gerelateerde technologieën, zoals webbakens, gebruiken om informatie op onze website te verzamelen.
                Een cookie is een tekstbestand dat door een webpaginaserver op uw harde schijf wordt geplaatst. Cookies kunnen niet worden 
                gebruikt om programma's uit te voeren of virussen op uw computer te bezorgen. Cookies zijn uniek aan u toegewezen en 
                kunnen alleen worden gelezen door een webserver in het domein dat de cookie aan u heeft verstrekt. Een van de belangrijkste 
                doelen van cookies is om een gemaksfunctie te bieden waarmee u tijd kunt besparen. Het doel van een cookie is om de 
                webserver te laten weten dat u bent teruggekeerd naar een specifieke pagina. Als u zich bijvoorbeeld bij ons registreert, 
                helpt een cookie I&SBouw bepaalde informatie over u te herinneren voor latere bezoeken. Dit vereenvoudigt het proces van 
                het vastleggen van uw persoonlijke gegevens, zoals factuuradressen, verzendadressen, enzovoort. Wanneer u terugkeert naar 
                dezelfde I&SBouw -website, kan de eerder verstrekte informatie worden opgehaald, zodat u eenvoudig de door u aangepaste 
                functies kunt gebruiken. Een webbaken is een kleine grafische afbeelding waarmee de partij die het webbaken heeft opgezet 
                bepaalde informatie over de bezoeker van de webpagina, het webdocument of het e-mailbericht kan controleren en verzamelen, 
                zoals het type browser dat het web opvraagt. . beacon, het IP-adres van de computer waarnaar het webbeacon is verzonden en 
                het tijdstip waarop het webbeacon is bekeken. Webbakens kunnen erg klein en onzichtbaar zijn voor de gebruiker, maar in het
                algemeen kan elke elektronische afbeelding die wordt bekeken als onderdeel van een webpagina of e-mail, inclusief op 
                HTML-gebaseerde inhoud, fungeren als een webbaken. We kunnen webbakens gebruiken om bezoekers van de webpagina's op de 
                website te tellen of om bij te houden hoe onze gebruikers door de website navigeren, en we kunnen webbakens in 
                e-mailberichten opnemen om te tellen hoeveel verzonden berichten daadwerkelijk zijn geopend, opgevolgd of doorgestuurd.
            </p>
            <p>
                Externe leveranciers kunnen ook cookies gebruiken op onze website. We kunnen bijvoorbeeld contracten sluiten met derden die 
                cookies op onze website zullen gebruiken om anonieme statistische informatie over het gebruik en het volume van onze 
                bezoekers en leden bij te houden en te analyseren. Dergelijke informatie wordt alleen extern gedeeld op anonieme, 
                geaggregeerde basis. Deze derde partijen gebruiken permanente cookies om ons te helpen de bezoekerservaring te verbeteren, 
                de inhoud van onze site te beheren en bezoekersgedrag te volgen.
            </p>
            <p>
                Om de effectiviteit van onze e-mailcommunicatie te helpen meten en verbeteren, plaatst de derde partij cookies. Alle 
                gegevens verstrekt door deze derde partij 
            </p>
            <h4>
                LINKS
            </h4>
            <p>
                Deze site bevat links naar andere sites die informatie geven die wij interessant vinden. I&SBouw is niet verantwoordelijk
                voor het privacybeleid of de inhoud van dergelijke websites.
            </p>
            <h4>
                OPENBARE DISCUSSIES
            </h4>
            <p>
                Deze site biedt mogelijk openbare discussies over verschillende onderwerpen over bedrijfswaardering. Houd er rekening
                mee dat alle informatie die u in deze threads plaatst, openbaar is, dus plaats geen gevoelige informatie in de 
                openbare threads. Telkens wanneer u informatie online vrijgeeft, kan die informatie door anderen worden verzameld 
                en gebruikt. Wij zijn niet verantwoordelijk voor enige actie of beleid van derden die informatie verzamelen die 
                gebruikers op dergelijke forums op de website bekendmaken. I&SBouw is het niet eens of oneens met iets wat op het 
                discussiebord is geplaatst. Vergeet ook niet om ons te houden aan ons andere geposte beleid over berichten op onze 
                openbare forums.
            </p>
            <h4>
                HOE KAN EEN GEBRUIKER PERSOONLIJKE GEGEVENS TOEGANG HEBBEN, WIJZIGEN EN/OF VERWIJDEREN?
            </h4>
            <p>
                U kunt alle persoonlijk identificeerbare informatie die u op de website plaatst, inzien, corrigeren, bijwerken en/of 
                verwijderen. Op de website kunt u zich ook afmelden voor mailinglijsten of aanmeldingen. Om dit te doen, volgt u de 
                instructies op de pagina van de website waar u dergelijke informatie hebt verstrekt, of schrijft u zich in of 
                registreert u, of neemt u contact met ons op via info@isbouw.nl
            </p>
            <h4>
                PRIVACY VAN KINDEREN
            </h4>
            <p>
                I&SBouw verzamelt niet bewust persoonlijke informatie (zoals de naam of het e-mailadres van een kind) van kinderen jonger 
                dan 13 jaar. Als u denkt dat we persoonlijke informatie hebben verzameld van een kind jonger dan 13 jaar, neem dan contact 
                met ons op.
            </p>
            <h4>
                VERANDERINGEN
            </h4>
            <p>
                I&SBouw behoudt zich het recht voor om deze verklaring op elk moment te wijzigen. Alle wijzigingen in dit privacybeleid 
                zullen in dit gedeelte worden vermeld en als dergelijke wijzigingen van wezenlijk belang zijn, wordt gedurende een 
                bepaalde periode een kennisgeving op de startpagina van de website geplaatst. Als u vragen heeft over privacy op websites 
                die worden beheerd door I&SBouw of onze websitepraktijken, neem dan contact met ons op via: info@isbouw.nl.
            </p>
        </div>
    </div>
    <IsBouwMedia/>
    <Footer/>
    </>
  )
}

export default PrivacyPolicy;
import React from 'react';
import algImage from "../../assets/Images/algemene.png";
import "./Algemene.css";

const Algemene = () =>{
  return (
    <div className='algemene-main'>
        <div className='algemene-content' data-aos="fade-up" data-aos-duration="3000">
            <h4>
                Algemene info
            </h4>
            <p>
                Bij I&S Bouw ontzorgen wij onze opdrachtgevers volledig. Van het aanleveren van potentiële projecten, architectuur, 
                financieringsmogelijkheden bekijken, bouwkundig advies, bouwen tot aan de volledige afwerking om alles onder 1 dak aan 
                te bieden. In de loop van de jaren hebben we projecten afgeleverd waar we trots op zijn en creëren we vaste relaties om 
                langdurig samen te werken.
            </p>
        </div>
        <div className='algemene-image' data-aos="flip-right" data-aos-duration="3000">
            <img src={algImage} alt='algemene-logo'/>
        </div>
    </div>
  )
}

export default Algemene;
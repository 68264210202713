import React from 'react';
import "./WorkerCard.css";

const WorkerCard = (props) => {
  return (
    <div className='workcard-main'>
        <img src={props.imageCard} alt='work-logo'/>
        <div className='worker-number'>
            <h4>
                {props.numb}
            </h4>
            <p>
                {props.numbText}
            </p>
        </div>
    </div>
  )
}

export default WorkerCard;
import React from 'react';
import ProjectenPara from '../Component/SubComponents/ProjectenPara/ProjectenPara';
import HeadParaBorder from '../Component/SubComponents/HeadParaBorder/HeadParaBorder';
import Arch1 from "../assets/Images/arch1.png";
import Arch2 from "../assets/Images/arch2.png";
import Arch3 from "../assets/Images/arch3.png";
import Arch4 from "../assets/Images/arch4.png";
import "./Architectuur.css";
import { Link } from 'react-router-dom';
import IsBouwMedia from '../Component/IsBouwMedia/IsBouwMedia';
import Footer from '../Component/Footer/Footer';
import MainHeader from '../Component/MainHeader/MianHeader';
const Architectuur = () => {
  return (
    <>
    <MainHeader/>
    <div className='architectuur-main'>
        <div className='architect-head'>
            <ProjectenPara
            projectheading="Alle diensten"
            projectparas="Alle diensten"/>
        </div>
       
        <div className='container'>
            <div className='architect-sec1'>
                    <div className='architect-sec1-content1' data-aos="fade-up" data-aos-duration="3000">
                    <HeadParaBorder
                    hphead="Architectuur"
                    hppara=" Binnen I&SBouw bieden wij het gehele architectuurwerk aan. Wij hebben enorme ervaring bij het verkrijgen van 
                    vergunningen, communicatie met de gemeentes, wijzigen van bestemmingen, splitsen van woningen en al het andere wat 
                    betrekking heeft met architectuur van uw pand.  "
                    hpspan="Heeft u hierbij ondersteuning nodig? Plan direct je consult in!"/>
                    <div className='architect-anc text-center'>
                        <Link to="/contactus">Klik dan hier!</Link>
                    </div>
                </div>
                <div className='architect-sec1-content2' data-aos="flip-right" data-aos-duration="3000">
                    <img src={Arch1} alt="architect-logo"/>
                </div>
                <div className='architect-sec1-content2' data-aos="flip-right" data-aos-duration="3000">
                    <img src={Arch2} alt="architect-logo"/>
                </div>
                    <div className='architect-sec1-content1' data-aos="fade-up" data-aos-duration="3000">
                    <HeadParaBorder
                    hphead="Projectadvies"
                    hppara="Met tientallen projecten achter de rug weet I&SBouw van A tot Z om u hierbij te helpen. Met vraagstukken 
                    betreft winstmarges, huurpotenties, regel en wetgeving, vergunningstrajecten ondersteunen wij u graag. Ook 
                    schatten wij in of het object winstgevend is."
                    hpspan=""/>
                    <div className='architect-anc text-center'>
                        <Link to="/contactus">Klik dan hier!</Link>
                    </div>
                </div>
                    <div className='architect-sec1-content1' data-aos="fade-up" data-aos-duration="3000">
                    <HeadParaBorder
                    hphead="Financiering Kansen"
                    hppara="Bent u geïnteresseerd in een pand en wilt u het maximale halen uit uw hypotheek? Bij I&SBouw kennen wij 
                    de beste financiële adviseurs die u op weg kunnen helpen. In de loop van jaren heeft I&SBouw met verschillende 
                    adviseurs gewerkt en kennen wij de beste die het maximale voor u kan betekenen."
                    hpspan="Heeft u hierbij ondersteuning nodig? Plan direct je consult in!"/>
                    <div className='architect-anc text-center'>
                        <Link to="/contactus">Klik dan hier!</Link>
                    </div>
                </div>
                <div className='architect-sec1-content2' data-aos="flip-right" data-aos-duration="3000">
                    <img src={Arch3} alt="architect-logo"/>
                </div>
                <div className='architect-sec1-content2' data-aos="flip-right" data-aos-duration="3000">
                    <img src={Arch4} alt="architect-logo"/>
                </div>
                <div className='architect-sec1-content1' data-aos="fade-up" data-aos-duration="3000">
                    <HeadParaBorder
                    hphead="Bouw"
                    hppara="I&SBouw bouwt van groot tot klein. Renovaties, nieuwbouw, transformaties en bedrijfsbouw zit in ons 
                    vakgebied. Met 15+ jaar ervaring en 50+ bouwexperts weten wij hoe we u kunnen verlossen bij elk project"
                    hpspan="Heeft u hierbij ondersteuning nodig? Plan direct je consult in!"/>
                    <div className='architect-anc text-center'>
                        <Link to="/contactus">Klik dan hier!</Link>
                    </div>
                </div>
            </div>
        </div>
        <IsBouwMedia/>
        <Footer/>
    </div>
    </>
  )
}

export default Architectuur;
import React from 'react';
import "./BtnSpan.css";
const BtnSpan = (props) => {
  return (
    <div className='btnspan-main'>
        {/* <Link to={props.linkdata}>{props.linktext}</Link> */}
        <span>
            {props.linkDataText}         
        </span>
    </div>
  )
}
export default BtnSpan;